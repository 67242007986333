import {
  getAuthenticatedUserByCache,
  rejectError,
  saveAuthenticatedUserToCache,
  saveDomainInfoToCache,
  removeDomainInfoByCache
} from "@/helpers";
import axiosInstance from "../../services";
import HttpService from '@/services/http-service';
export default {
  namespaced: true,
  state: {
    receivePaymentRequest: {
      order_amount: '',
      currency: "EUR",
      return_url: '',
      failure_url: '',
      description: '',
      ip_address: '127.0.0.1',
      customer: {
        reference_id: '',
        first_name: '',
        last_name: '',
        email: ''
      },
      settlement_currency: {
        code: null
      }
    },
    merchantPaymentSetting: {},
    bankInformations: {},
    headerBalance: {},
    deleteBankInformationResponse: {},
    createBankInformation: {},
    updateBankInformation: {},
    bankDetail: {},
    merchantDetail: {},
    merchantBusinessInfo: {},
    updateMerchantSettings: {},
    merchantCurrencies: [],
    merchantCryptoCurrencies: [],
    merchantTradeCryptoCurrencies: [],
    emailVerifyCode: {},
    merchantAppForm: {},
    settlementCurrencies: [],
    totalBalanceBySelectedFiat: {},
    merchantCryptoCurrenciesInfo: [],
    privileges: {
      categories: [],
      privilegeTypes: []
    },
  },
  getters: {
    getPriviligiesCategories(state) {
      return state.privileges.categories
    },
    getPriviligies(state) {
      return state.privileges.privilegeTypes
    },
    merchantAppForm(state) {
      return state.merchantAppForm;
    },
    getSettlementCurrencies(state) {
      return state.settlementCurrencies
    },
    receivePaymentRequest(state) {
      return state.receivePaymentRequest;
    },
    getMerchantPaymentSetting(state) {
      return state.merchantPaymentSetting;
    },
    getBankInformations(state) {
      if (state.bankInformations) {
        const {
          content,
          totalElements,
          size,
          totalPages
        } = state.bankInformations;

        return { content, totalElements, size, totalPages };
      }
      return null;
    },
    getBankDetail(state) {
      if (state.bankDetail) {
        return state.bankDetail;
      }
      return null;
    },
    getMerchantHeaderBalance(state) {
      return state.headerBalance;
    },
    getDeleteBankInformation(state) {
      return state.deleteBankInformationResponse;
    },
    getCreateBankInformation(state) {
      return state.createBankInformation;
    },
    getUpdateBankInformation(state) {
      return state.updateBankInformation;
    },
    getMerchantInfo(state) {
      return state.merchantDetail;
    },
    getMerchantBusinessInfo(state) {
      return state.merchantBusinessInfo;
    },
    getupdateMerchantSettings() {
      return this.state.updateMerchantSettings;
    },
    isActivateButtonDisabled(state) {
      let authenticatedUser = getAuthenticatedUserByCache();

      return authenticatedUser.userVerified === 'false' &&
        Object.keys(state.merchantAppForm).length > 0 &&
        (state.merchantAppForm.status === 'REJECTED' || state.merchantAppForm.status === 'SUBMITTED');
    },
    getCurrencies(state) {
      return state.merchantCurrencies.map(currency => ({ ...currency,  icon: `${process.env.VUE_APP_STATIC_RESOURCE_ROOT}/currency/${currency.code}.svg`, }));
    },
    getCurrenciesWithoutLimit(state) {
      let merchantCurrencies = state.merchantCurrencies;
      return merchantCurrencies;
    },
    getCryptoCurrencies(state) {
      let cryptoCurrencies = state.merchantCryptoCurrencies;

      cryptoCurrencies = cryptoCurrencies.map((item) => {
        return {
          ...item,
          icon: `${process.env.VUE_APP_STATIC_RESOURCE_ROOT}/coin/${item.code}.svg`,
        };
      });

      return cryptoCurrencies;
    },
    getTradeCryptoCurrencies(state) {
     return state.merchantTradeCryptoCurrencies;

    },
    getEmailVerifyCode(state) {
      let emailVerifyCode = state.emailVerifyCode;

      return emailVerifyCode;
    },
    getMerchantCryptoCurrenciesInfo(state) {
      let cryptoCurrenciesInfo = state.merchantCryptoCurrenciesInfo;
      return cryptoCurrenciesInfo;
    },
    getTotalBalanceBySelectedFiat(state) {
      return state.totalBalanceBySelectedFiat
    }
  },
  actions: {
    async getPrivilegeList({commit}) {
      try {
        const res = await axiosInstance.get('/merchants/merchantuser/panel/privileges')
        if(res.data.code === '00') {
          commit('SET_PRIVILIGIES', res.data.data)
        }
      } catch(err) {
        console.log(err)
      }
    },
    async saveCurrencyManagement(_, data) {
      try {
        const res = await axiosInstance.post(`/merchants/panel/currency/management`, data);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getCurrencyManagement() {
      try {
        const res = await axiosInstance.get(`/merchants/panel/currency/management`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async updateAutoConversionSetting(_, data) {
      try {
        const res = await axiosInstance.put(`/merchants/panel/autoConversion/setting`, data);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getAutoConversionSetting() {
      try {
        const res = await axiosInstance.get(`/merchants/panel/autoConversion/setting`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getAllByCryptoCurrencyCodes() {
      try {
        const res = await axiosInstance.get(`merchants/panel/cryptocurrency/settlement/allByCryptoCurrencyCodes`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async pullInputCustomerSearch(_, data) {
      try {
        const res = await axiosInstance.get(`customer/panel/customer/email/${data}`);
        const response = res.data;

        if (response.code === "00" && response.status === "APPROVED") {
          if (process.env.NODE_ENV === "development") {
            window.console.log(response.data);
          }

          return response.data;
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    async getArchivedBankList({commit}, data) {
      const {page} = data
      try {
        const response = await axiosInstance.get(`/merchants/bank-information/panel/list?page${page}&isArchived=true&showIsUsed=true&type=WITHDRAW`)
        return response.data.data
      } catch (error) {
        return rejectError(error)
      }
    },
    async getApprovedBankAccountList(_, data) {
      const { currency } = data
      try {
        const response = await axiosInstance.get(`/merchants/bank-information/panel/approved-list?currency=${currency}&type=WITHDRAW`)
        return response.data.data
      } catch (error) {
        return rejectError(error)
      }
    },
    async getMerchantBankInformations({ commit }, data) {
      const { page, currency, isArchived } = data;
      try {
        let res;
        if(currency) {
          res = await axiosInstance.get(
            `/merchants/bank-information/panel/list?page=${page}&currency=${currency}&type=WITHDRAW`
          );
        }

        if(isArchived === 'withoutArchived') {

          res = await axiosInstance.get(
            `/merchants/bank-information/panel/list?page=${page}&isArchived=false&showIsUsed=true&type=WITHDRAW`
          );
        }
        if(!currency && !isArchived){
          res = await axiosInstance.get(
            `/merchants/bank-information/panel/list?page=${page}&showIsUsed=true&type=WITHDRAW`
          );
        }
        const response = res.data;

        if (response.code === "00" && response.status === "APPROVED") {

          commit("setBankInformations", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getMerchantBankDetail({ commit }, data) {
      const { bankId } = data;

      try {
        const res = await axiosInstance.get(`/merchants/bank-information/panel/${bankId}`);
        const response = res.data;

        if (response.code === "00" && response.status === "APPROVED") {
          commit("setBankDetail", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async deleteMerchantBankInformation({ commit }, data) {
      const { id } = data;

      try {
        const res = await axiosInstance.delete(`/merchants/bank-information/panel/${id}`);
        const response = res.data;

        if (response.code === "00" && response.status === "APPROVED") {
          if (process.env.NODE_ENV === "development") {
            window.console.log(response.data);
          }

          commit("setBankInformations", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async createMerchantBankInformation({ commit }, data) {
      try {
        const res = await axiosInstance.post(`/merchants/bank-information/panel/create`, data);
        const response = res.data;

        if (response.code === "00" && response.status === "APPROVED") {
          if (process.env.NODE_ENV === "development") {
            window.console.log(response.data);
          }

          commit("setCreateBankInformation", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async createFixedDepositUrl({ getters }, hasReferenceId) {
      try {
        let response = {};

        if (hasReferenceId) {
          const res = await axiosInstance.get(`/customer/panel/customer/referenceId/${getters.receivePaymentRequest.customer.reference_id}`);
          response = res.data;
        } else {
          response = {
            code: "00",
            status: "APPROVED"
          }
        }

        if (response.code === "00" && response.status === "APPROVED") {
          if (typeof response.data !== 'undefined') {
            getters.receivePaymentRequest.customer = response.data;
          }

          if( getters.receivePaymentRequest.billNumber ) {
            let billPayload = {
              ...getters.receivePaymentRequest,
              currency: getters.receivePaymentRequest.currency.code,
              settlement_currency: getters.receivePaymentRequest.settlement_currency ? getters.receivePaymentRequest.settlement_currency.code : null
            }
            const res2 = await axiosInstance.post(`/orders/panel/bill/url`, billPayload);
            const response2 = res2.data;

            if (response2.code === "00" && response2.status === "APPROVED") {
              if (process.env.NODE_ENV === "development") {
                window.console.log(response2.data);
              }

              return {
                error: '',
                detail: response2.data
              }
            } else {
              return {
                error: response2.message,
                detail: ''
              }
            }

          }

          let urlPayload = {
            ...getters.receivePaymentRequest,
            currency: getters.receivePaymentRequest.currency.code,
            settlement_currency: getters.receivePaymentRequest.settlement_currency ? getters.receivePaymentRequest.settlement_currency.code : null
          }
          const res2 = await axiosInstance.post(`/payments/panel/url`, urlPayload);
          const response2 = res2.data;

          if (response2.code === "00" && response2.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response2.data);
            }

            return {
              error: '',
              detail: response2.data
            }
          } else {
            return {
              error: response2.message,
              detail: ''
            }
          }
        } else {
          return {
            error: 'Reference id is not valid.',
            detail: ''
          }
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async updateMerchantBankInformation({ commit }, data) {
      try {
        const res = await axiosInstance.put(`/merchants/bank-information/panel/update`, data);
        const response = res.data;

        if (response.code === "00" && response.status === "APPROVED") {
          if (process.env.NODE_ENV === "development") {
            window.console.log(response.data);
          }

          commit("setUpdateBankInformation", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getMerchantHeaderBalanceWithCurrency(_, balanceCurrencyCode) {
      try {
        const res = await axiosInstance.get(`/balance/merchant-balance/panel/${balanceCurrencyCode}`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          return response;
        } else {
          rejectError("there is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getMerchantHeaderBalance({ commit }) {
      try {
        const res = await axiosInstance.get(`/balance/merchant-balance/panel/`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          let authenticatedUser = getAuthenticatedUserByCache();
          let authenticatedUserWithBalanceCurrency = {
            ...authenticatedUser,
            balanceCurrency: response.data.exchange.currency,
            isRefleshToken: "false"
          };
          await saveAuthenticatedUserToCache(authenticatedUserWithBalanceCurrency);

          await commit("setMerchantHeaderBalance", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getDepositWithdrawSubMerchants() {
      try {
        const response = await axiosInstance.get(`/merchants/panel/fiat-transaction-merchants`);
        return response.data.data;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getBankInformation() {
      try {
        const response = await axiosInstance.get(`/merchants/panel/merchant/depositBankAccounts`);
        return response.data.data;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getMerchantBankInformation(_, data) {
      const { merchantId } = data;
      try {
        const response = await axiosInstance.get(`/contents/bankAccount/panel/merchant/${merchantId}/shown`);
        return response.data.data;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getMerchantInfo({ commit }) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/summary`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          commit("setMerchantInfo", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getMerchantPaymentSetting({ commit }) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/merchant/paymentSetting`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          commit("setMerchantPaymentSetting", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getMerchantAppForm({ commit, getters }) {
      try {
        if (getters.getMerchantInfo.appformToken == null) {
          return true;
        }

        const res = await HttpService.get(`${process.env.VUE_APP_APPFORM_API_URL}/appform/${getters.getMerchantInfo.appformToken}`);
        if (res.code === 1000) {
          commit("setMerchantAppForm", res.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async updateMerchantPaymentSetting({ commit }, data) {
      try {
        const res = await axiosInstance.post(`/merchants/panel/merchant/paymentSetting`, data);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          commit("setMerchantPaymentSetting", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getMerchantBusinessInfo({ commit }) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/merchant/businessInfo`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          commit("setMerchantBusinessInfo", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async updateMerchantBusinessInfo({ commit }, data) {
      try {
        const res = await axiosInstance.post(`/merchants/panel/merchant/businessInfo`, data);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          response.data.countryCode = parseInt(response.data.countryCode, 10);
          commit("setMerchantBusinessInfo", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async updateMerchantSettings({ commit }, data) {
      try {
        const res = await axiosInstance.put(`/merchants/panel/settings`, data);
        const response = res.data;

        if (response.code === "00" && response.status === "APPROVED") {
          if (process.env.NODE_ENV === "development") {
            window.console.log(response.data);
          }

          commit("setUpdateMerchantSettings", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getCurrencies({ commit, rootState }) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/currency/supported`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          await commit("setCurrencies", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getCryptoCurrencies({ commit }, data) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/cryptocurrency/withdraw`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          commit("setCryptoCurrencies", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getDomainInfo(_, data) {
      try {
        let domain_url = data.domain_url;
        const res = await axiosInstance.post(`/merchants/panel/info`, {
          domain_url
        });
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          saveDomainInfoToCache(response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          window.console.log(err);
        }
        return rejectError(err);
      }
    },
    async getDomainInfoWithToken() {
      try {
        const res = await axiosInstance.get(`/merchants/panel/info-with-token`);
        const response = res.data;
        localStorage.removeItem('domain-config')
        localStorage.setItem('domain-config', JSON.stringify(response.data))
        return response
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          window.console.log(err);
        }
        return rejectError(err);
      }
    },
    async getTradeCryptoCurrencies({ commit }) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/cryptocurrency/trade`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          await commit("setTradeCryptoCurrencies", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async sendWithdrawEmailVerify({ commit }, data) {
      try {
        const res = await axiosInstance.post(`/merchants/panel/merchant/send/email-verify`, data);
        const response = res.data;

        if (response.code === "00" && response.status === "APPROVED") {
          if (process.env.NODE_ENV === "development") {
            window.console.log(response.data);
          }

          commit("setEmailVerifyCode", response.data);
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getCustomerByEmail({ getters }, email) {
      try {
        let response = {};
        if (email) {
          const res = await axiosInstance.get(`/customer/panel/customer/email/${email}`);
          response = res.data
          if (response.hasOwnProperty('data')){
            getters.receivePaymentRequest.customer = response.data;
          } else {
            getters.receivePaymentRequest.customer = {
              reference_id: '',
              first_name: '',
              last_name: '',
              email: ''
            };
          }
        }
      } catch (err) {
        getters.receivePaymentRequest.customer = {
          reference_id: '',
          first_name: '',
          last_name: '',
          email: ''
        };
        return rejectError(err);
      }
    },
    createCustomer({ getters, state, }, data) {

      data.merchant_id = state.merchantDetail.code;

      return axiosInstance.post(`/customer/panel/customer/create`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
              if (process.env.NODE_ENV === "development") {
                window.console.log(response.data);
              }

              getters.receivePaymentRequest.customer = {
                reference_id: data.reference_id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email
              };
          }
          else {
              rejectError("There is an unexpected error")
          }
        })
        .catch(err => rejectError(err))
    },
    async getCryptoCurrenciesInfo({ commit }) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/cryptocurrency/all`);
        const response = res.data;
        if (response.code === "00" && response.status === "APPROVED") {
          commit("setCryptoCurrenciesInfo", response.data);
          return response;
        } else {
          rejectError("There is an unexpected error");
        }
      } catch (err) {
        return rejectError(err);
      }
    },
    async getAddressList(_, data) {
      const { page, filters, limit } = data;
      const {
        cryptoCurrencyCode,
        firstName,
        lastName,
        email,
        address,
        network
      } = filters;

      const postData = {
        cryptoCurrencyCode,
        firstName,
        lastName,
        email,
        address,
        network
      };

      try {
        const res = await axiosInstance.post(`/customer/panel/dynamic/deposit/address/list/filter?page=${page}&size=${limit}`, postData);
        return res.data.data;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getNetworkList(_, data) {
      const { coin } = data;
      try {
        const res = await axiosInstance.get(`/customer/panel/deposit/networks/${coin}`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getSettlementCurrencyList(_) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/cryptocurrency/settlement`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async beneficiaryVaspDidList(_, data) {
      try {
        const res = await axiosInstance.get(`/travel-rule/notabene/panel/all-vasp-list`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getSettlementCurrencyListAll(_, data) {
      try {
        const res = await axiosInstance.get(`/merchants/panel/currency/settlement/all/${data}`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getTradeCurrencyCryptocurrencyList() {
      try {
        const res = await axiosInstance.get(`/merchants/panel/trade/currencyCryptocurrency`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getTradeCurrencyList() {
      try {
        const res = await axiosInstance.get(`/merchants/panel/cryptocurrency/trade`);
        const response = res.data;
        return response;
      } catch (err) {
        return rejectError(err);
      }
    },
    async createNewAddress({ commit }, data) {
      const { createData } = data;
      const {
        cryptoCurrencyCode,
        firstName,
        lastName,
        email,
        address,
        note,
        network,
        settlement_currency
      } = createData;

      const postData = {
        cryptoCurrencyCode,
        firstName,
        lastName,
        email,
        address,
        note,
        network,
        settlement_currency : settlement_currency == 'None' ? null : settlement_currency.code
      };
      try {
        const res = await axiosInstance.post(`/customer/panel/dynamic/deposit/address`, postData);
        return res.data.data;
      } catch (err) {
        return rejectError(err);
      }
    },
    async getSettlementFiatCurrencies({commit}) {
      try {
        let response = await axiosInstance.get('/merchants/panel/price-currency/settlement')
        await commit('setSettlementFiatCurrencies', response.data.data)
        return response
      }catch (e) {
        if (process.env.NODE_ENV === "development") {
          window.console.log(e)
        }
      }
    },

  },
  mutations: {
    SET_PRIVILIGIES(state, data) {
      return state.privileges = data
    },
    setSettlementFiatCurrencies(state, list) {
      return state.settlementCurrencies = list
    },
    setTotalBalanceBySelectedFiat(state, balance) {
      return state.totalBalanceBySelectedFiat = balance
    },
    setBankInformations(state, bankInformations) {
      return (state.bankInformations = bankInformations);
    },
    setMerchantHeaderBalance(state, headerBalance) {
      return (state.headerBalance = headerBalance);
    },
    setDeleteBankInformation(state, response) {
      return (state.deleteBankInformationResponse = response);
    },
    setCreateBankInformation(state, response) {
      return (state.createBankInformation = response);
    },
    setUpdateBankInformation(state, response) {
      return (state.updateBankInformation = response);
    },
    setBankDetail(state, response) {
      return (state.bankDetail = response);
    },
    setMerchantInfo(state, response) {
      return (state.merchantDetail = response);
    },
    setMerchantPaymentSetting(state, response) {
      return (state.merchantPaymentSetting = response);
    },
    setMerchantBusinessInfo(state, response) {
      return (state.merchantBusinessInfo = response);
    },
    setUpdateMerchantSettings(state, response) {
      return (state.updateMerchantSettings = response);
    },
    setCurrencies(state, currencies) {
      console.log('currencies', currencies)
      currencies = currencies.map((item) => {
        console.log('item', item)
        return {
          ...item,
          icon: `${process.env.VUE_APP_STATIC_RESOURCE_ROOT}/currency/${item.code}.svg`,
        };
      });
      return (state.merchantCurrencies = currencies);
    },
    setCryptoCurrencies(state, cryptoCurrencies) {
      return (state.merchantCryptoCurrencies = cryptoCurrencies);
    },
    setTradeCryptoCurrencies(state, cryptoCurrencies) {
      return (state.merchantTradeCryptoCurrencies = cryptoCurrencies);
    },
    setEmailVerifyCode(state, emailVerifyCode) {
      return (state.emailVerifyCode = emailVerifyCode);
    },
    setMerchantAppForm(state, response) {
      return (state.merchantAppForm = response);
    },
    setCryptoCurrenciesInfo(state, cryptoCurrenciesInfo) {
      return (state.merchantCryptoCurrenciesInfo = cryptoCurrenciesInfo);
    },
  }
};
